export const projectDetails =
    [
        {
            name: 'Bear Cobble HQ',
            date: '2020 - present',
            status: 'Version 2 live',
            descriptionShort: 'A dashboard for monitoring equipment sensors, recording issues, viewing sugarwoods maps, and weather forecast output.',
            descriptionLong: 'Maple sugaring is a challenging endevor due to the nature of the harvest.  A window of approximately 6 to at best 8 weeks is when producers make 100% of their crop.  This means when the weather is favorable, all systems must be operational.  Equipment downtime, issues in the woods, lost sap, these are all things that can ruin a season, and keep sugarmakers up at night. For Bear Cobble Sugarworks, this meant having a single reliable location to go and track this information was a necessity.  The solution: Bear Cobble HQ.',
            imgFileName: 'bearCobbleLaptop2.png',
            imgAlt: 'A screenshot of Bear Cobble HQ a digital dashboard for Bear Cobble Sugarworks. Image shows the site\'s navigation, sensor display, weather conditions and forecast display and a list of pending infrastructure fixes.',
            mobileImgFileName: 'bearCobbleMobile.png',
            url: 'https://bearcobble.com',
            technologies: ['Next.js', 'React', 'Sass', 'HTML/CSS3', 'Express.js', 'Vercel', 'Heroku', 'Node.js', 'MySQL', 'Smartrek'],
            videos: [{
                name: "App Walkthrough",
                description: "Walkthrough of Bear Cobble HQ - A Maple Production Systems Management Application",
                src: "https://sg-web-dev-portfolio.s3.amazonaws.com/BCSW+App+Walkthrough+720p.mp4",
                captionSrc: "https://sg-web-dev-portfolio.s3.amazonaws.com/BCSW+App+Walkthrough+Captions.vtt",
                thumbnail: "https://sg-web-dev-portfolio.s3.amazonaws.com/BCSW+App+Walkthrough+Thumbnail.png"
            },
            ]
        },
        {
            name: 'Creek VT',
            date: '2019 - present',
            status: 'Version 1 live, version 2 changes in progress',
            descriptionShort: 'An array of tools for whitewater kayakers to better understand and predict river levels.  Includes gauge correlations to USGS gauges, remote cameras, rainfall totals and forecasts, weather radar, recent river reports, race registration and custom admin CMS.',
            descriptionLong: 'Whitewater kayaking in the state of Vermont is about as weather dependent as any recreation endeavor can be.  In the dry season a rainstorm can bring opportunity measured in hours.  As such kayakers are constantly honing methods to determine if conditions are right.  Creek VT has made the next step in the evolution of these methods.  By integrating existing publically available resources and real-time information with their own system of capturing remote visuals, kayakers in the state now have a one-stop shop for determining when their favorite river has enough water to paddle. ',
            imgFileName: 'creekVTLaptop2.png',
            imgAlt: 'A screenshot of Creek VT a website for Vermont whitewater kayakers. Image shows the site\'s navigation, an image of a kayaker going over a small falls, and the sites logo.',
            mobileImgFileName: 'creekVTMobile2.png',
            url: 'https://creekvt.com/flows',
            technologies: ['Javascript', 'HTML/CSS3', 'Handlebars', 'Bootstrap', 'PHP', 'MySQL', 'Express.js', 'AWS S3', 'Heroku', 'Wordpress', 'React', 'Node.js'],
            videos: []
        },
        {
            name: 'Jeopardy',
            date: 'Summer 2022',
            status: '',
            descriptionShort: 'A project for Burlington Code Academy demonstrating mastery of DOM Manipulation via Javascript, HTML and CSS',
            descriptionLong: 'The classic game of Jeopardy brought to your web-browser.  Built to mimic the gameplay as close as possible, with a few fun twists.  Select your opponents (from the all-time champion Ken Jennings, to a pet-rock) and challenge them to two rounds of Jeopardy, and see if you can walk away a champion in Final Jeopardy.',
            imgFileName: 'jeopardyLaptop.png',
            imgAlt: 'A screenshot of a website mimicking jeopardy.',
            url: 'https://scottgilbert-jeopardy.vercel.app/',
            technologies: ['Javascript', 'HTML', 'CSS', 'SASS'],
            videos: []
        },
        {
            name: 'Pixel Art Editor',
            date: 'Fall 2021',
            status: '',
            descriptionShort: 'A project for Burlington Code Academy demonstrating mastery of DOM Manipulation via Javascript, HTML and mastery of CSS3 including animations and transitions',
            descriptionLong: 'Channel your inner Bob Ross with this pixel art editor.  Create your next masterpiece using the crayola suite of colors.  A simple yet fun way to pass some time.',
            imgFileName: 'pixelArtEditorLaptop.png',
            imgAlt: 'A screenshot of a pixel art editor with the text \'Hello World\' written out in various colors.',
            url: 'https://maplegilbs.github.io/pixel-art/',
            technologies: ['Javascript', 'HTML', 'CSS'],
            videos: []
        },
        {
            name: 'Sap Mappers',
            date: 'Fall 2021',
            status: '',
            descriptionShort: 'Homesite for SapMappers a sugarwoods mapping and GIS services company.',
            descriptionLong: 'Sapmappers is a company offering GIS services primarily to large scale sugaring operations in the northeast.  The site at sapmappers.com was built to showcase the company, including capabilities, completed works and reasons for utilizing their services. Simple, clean, effective.',
            imgFileName: 'sapmappersLaptop.png',
            imgAlt: '',
            mobileImgFileName: 'sapmappersMobile.png',
            url: 'https://sapmappers.com',
            technologies: ['Javascript', 'HTML', 'CSS', 'Bootstrap'],
            videos: []
        },
    ]