export const imagesList = [
    {
        fileName: 'seaKayakingElba.jpg',
        alt: '',
        caption: ''
    },
    {
        fileName: 'seaKayakingBeachCampNight.jpg',
        alt: '',
        caption: ''
    },
    {
        fileName: 'mountainSunsetDog.jpg',
        alt: '',
        caption: ''
    },
    {
        fileName: 'sugaringView.jpg',
        alt: '',
        caption: ''
    },
    {
        fileName: 'whitewaterKayakingMiddleburyGorge.jpg',
        alt: '',
        caption: ''
    },
    {
        fileName: 'plantSeedlings.jpg',
        alt: '',
        caption: ''
    },
    {
        fileName: 'tomatoColors.jpg',
        alt: '',
        caption: ''
    },
    {
        fileName: 'caving.jpg',
        alt: '',
        caption: ''
    },
    {
        fileName: 'skiing.jpg',
        alt: '',
        caption: ''
    },
    // {
    //     fileName: 'whitewaterKayakingShakerMill.jpg',
    //     alt: '',
    //     caption: ''
    // },
]